<template>
	<div id="login">
		<div class="loginBox">
			<div class="banner">
				<div class="logo">
					<img src="../../assets/imgs/logo.png" alt="">
				</div>
				<img class="back" src="../../assets/imgs/loginBack.png">
			</div>
			<div class="box">
				<h1>欢迎登录</h1>
				<el-form :model="form" label-width="100%" label-position="top" :rules="rules" ref="ruleForm"
					class="loginForm">
					<el-form-item label="手机号" prop="phoneNumber">
						<el-input v-model="form.phoneNumber" placeholder="请输入手机号" maxlength="11" clearable></el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="verifyCode" v-if="loginType == 'code'">
						<el-input v-model="form.verifyCode" placeholder="请输入验证码" clearable
							@keyup.native.enter="submitForm('ruleForm')">
							<p slot="append" @click="sendCode(form.phoneNumber)">{{btnText}}</p>
						</el-input>
					</el-form-item>
					<el-form-item label="密码" prop="passWord" v-if="loginType == 'pwd'">
						<el-input v-model="form.passWord" type="password" placeholder="请输入密码" clearable
							@keyup.native.enter="submitForm('ruleForm')"></el-input>
					</el-form-item>
				</el-form>
				<div class="other">
					<b @click="changeLoginType()">使用其他方式登录</b>
					<el-link :underline="false" type="primary" v-if="loginType == 'pwd'">忘记密码</el-link>
				</div>
				<div class="opts">
					<el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
					<p>
						还没有账号？<el-button type="text" @click="goToRegister()">去注册</el-button>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		login,
		sendCode,
		getDoctorInfo
	} from '@/request/api.js';
	const validateMobile = (rule, value, callback) => {
		let valueReg = /^1[3-9]\d{9}$/;
		if (!valueReg.test(value)) {
			callback(new Error('请输入正确的手机号'));
		} else {
			callback();
		};
	};
	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				loginType: 'pwd',
				btnText: "获取验证码",
				second: 60,
				form: {
					phoneNumber: '',
					passWord: '',
					verifyCode: ''
				},
				rules: {
					phoneNumber: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							validator: validateMobile.bind(this),
							trigger: 'blur'
						}
					],
					passWord: [{
						required: true,
						message: '请输入密码',
						trigger: 'change'
					}],
					verifyCode: [{
						required: true,
						message: '请输入验证码',
						trigger: 'change'
					}]
				}
			}
		},
		watch: {},
		created() {},
		mounted() {},
		methods: {
			// 去注册
			goToRegister() {
				this.$router.push({
					name: 'register'
				});
			},
			// 获取验证码
			sendCode(e) {
				let valueReg = /^1[3-9]\d{9}$/;
				if (!e) {
					this.$notify.error({
						title: '错误',
						message: '请输入手机号',
						position: 'bottom-right'
					});
				} else if (!valueReg.test(e)) {
					this.$notify.error({
						title: '错误',
						message: '请输正确的入手机号',
						position: 'bottom-right'
					});
				} else {
					if (this.second >= 60) {
						sendCode(this.form.phoneNumber).then(res => {
							this.getCode();
							console.log(res);
						}).catch(err => {
							console.log(err);
						});
					};
				};
			},
			getCode() {
				this.btnText = `${this.second}秒后再次获取`;
				this.second--;
				if (this.second < 0) {
					this.btnText = '获取验证码';
					this.second = 60;
				} else {
					setTimeout(() => {
						this.getCode();
					}, 1000);
				};
			},
			// 提交登录
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loginFun();
					};
				});
			},
			loginFun() {
				let data = {
					phoneNumber: this.form.phoneNumber
				};

				if (this.loginType == 'code') {
					data.verifyCode = this.form.verifyCode;
				} else if (this.loginType == 'pwd') {
					data.password = this.form.passWord;
				};

				login(data).then(res => {
					console.log("login", res)
					if (res && res.code == 200) {
						localStorage.setItem('token', JSON.stringify(res.data))
						if (res.data.type == 'Doctor') {
							// 医生用户完善信息验证
							this.getDoctorInfoFun();
						} else if (res.data.type == 'ASSISTANT') {
							this.$router.push({
								name: 'project'
							});
						} else if (res.data.type == 'OFFICIAL') {
							this.$router.push({
								name: 'official'
							});
						};
					} else {
						this.$notify.error({
							title: `错误`,
							message: res.msg,
							position: 'bottom-right'
						});
					};
				});
			},
			getDoctorInfoFun() {
				getDoctorInfo().then(res => {
					if (res.code == 200) {
						let userInfo = res.data;
						if (!userInfo.password) {
							this.$notify({
								title: `提示`,
								message: '请先设置登录密码',
								position: 'bottom-right',
								type: 'warning'
							});
							this.$router.push({
								name: 'setpwd'
							});
						} else if (userInfo.isUpload == "NULL") {
							this.$notify({
								title: `提示`,
								message: '请完成个人认证',
								position: 'bottom-right',
								type: 'warning'
							});
							this.$router.push({
								name: 'upLoadInfo'
							});
						} else if (!userInfo.fullName) {
							this.$notify({
								title: `提示`,
								message: '请完善个人信',
								position: 'bottom-right',
								type: 'warning'
							});
							this.$router.push({
								name: 'ImproveInfo'
							});
						} else if (userInfo.status == 'ERROR') {
							this.$alert('您的注册请求被驳回，请检查并完善信息后重新注册。', '提示', {
								confirmButtonText: '确定',
								type: 'info',
								center: true,
								callback: action => {
									this.$router.push({
										name: 'ImproveInfo'
									});
								}
							});
						} else if (!userInfo.projectId) {
							this.$notify({
								title: `提示`,
								message: '请选择要参加的项目',
								position: 'bottom-right',
								type: 'warning'
							});
							this.$router.push({
								name: 'SelectProject'
							});
						} else {
							this.$router.push({
								name: 'meetlist'
							});
						};
					};
				});
			},
			// 切换登录状态
			changeLoginType() {
				if (this.loginType == 'code') {
					this.loginType = 'pwd';
				} else {
					this.loginType = 'code';
				};
			}
		}
	}
</script>

<style lang="scss" scoped>
	#login {
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(244, 249, 253, 1);

		.loginBox {
			width: 90%;
			max-width: 1200px;
			height: 90%;
			display: flex;
			overflow: hidden;
			border-radius: 20px;
			flex-direction: row;
			box-shadow: 0 0 20px rgba(0, 0, 0, .1);

			.banner,
			.box {
				flex: 1;
				background-color: #fff;
			}


			.banner {
				// background-color: rgba(63, 140, 255, 1);
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: relative;

				.logo {
					flex: 1;
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					position: absolute;
					top: 40px;
					left: 40px;

					img {
						height: 80px;
					}
				}

				.back {
					width: 400px;
					height: 500px;
					object-fit: contain;
				}
			}

			.box {
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				h1 {
					font-size: 20px;
					margin-bottom: 30px;
				}

				.loginForm {
					width: 70%;
					display: flex;
					flex-direction: column;

					* {
						font-size: 14px;
					}

					.el-input {
						height: 40px;
					}
				}

				.other {
					width: 70%;
					display: flex;
					margin: 30px 0;
					flex-direction: row;
					justify-content: space-between;

					* {
						font-size: 13px;
					}

					b {
						cursor: pointer;
						font-weight: normal;
					}
				}

				.opts {
					width: 70%;
					display: flex;
					align-items: center;
					margin-bottom: 10px;
					flex-direction: column;
					justify-content: center;

					.el-button {
						width: 120px;
					}

					p {
						color: #B2BAC6;
						margin-top: 20px;

						.el-button {
							padding: 0;
							width: auto;
							color: #2A82E4;
						}
					}
				}
			}
		}
	}
</style>